.card {
  position: relative;
  width: 100%;
  height: 300px;
  padding-bottom: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped {
  transform: rotateY(180deg);
}

.card .front,
.card .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.card .front {
  z-index: 2;
  transform: rotateY(0deg);

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}



.card .front .content {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
  color: #fff;
  text-align: center;
}


.card .back {
  transform: rotateY(180deg);
  background-color: #F7F3DF;
  color: #000;
  padding: 10px;
}

.card .back .description {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card .back p {
  text-align: center;
}


.alpa{
  background-image: url('../images/alpalhao.jpeg');
}

.marv{
  background-image: url('../images/Marvao.avif');
}

.nisa{
  background-image: url('../images/nisa.avif');
}

.crato{
  background-image: url('../images/crato.avif');
}

.castelo{
  background-image: url('../images/cdvide.jpeg');
}

.porta{
  background-image: url('../images/portalegre.avif');
}

.alter{
  background-image: url('../images/Tomar.avif');
}

.evora{
  background-image: url('../images/evora.avif');
}

.rando{
  background-image: url('../images/rando.avif');
}

.padel{
  background-image: url('../images/padel.avif');
}

.povoa{
  background-image: url('../images/povoa.jpeg');
}

.wake{
  background-image: url('../images/wakeboard.avif');
}

.fete{
  background-image: url('../images/fete.avif');
}

.karting{
  background-image: url('../images/karting.avif');
}

.animals{
  background-image: url('../images/animaux.avif');
}

.dolmen{
  background-image: url('../images/dolmen.avif');
}
